import React, { ReactNode } from 'react'

const ModalContext = React.createContext<{
	modalOpen: boolean
	modalOpenFunction: (open: boolean) => void
	modalContent: ReactNode
	modalContentFunction: (content: ReactNode) => void
} | null>(null)

const ModalProvider = (props: { children: ReactNode }) => {
	const [modalOpen, setmodalOpen] = React.useState(false)
	const [modalContent, setModalContent] = React.useState<ReactNode>(null)

	const modalOpenFunction = (open: boolean) => {
		setmodalOpen(open)
	}

	const modalContentFunction = (content: ReactNode) => {
		setModalContent(content)
	}
	return (
		<ModalContext.Provider
			value={{ modalOpen, modalOpenFunction, modalContent, modalContentFunction }}>
			{props.children}
		</ModalContext.Provider>
	)
}

export { ModalContext, ModalProvider }
