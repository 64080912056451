import React, { ReactNode } from 'react'

const ShoppingContext = React.createContext<{
	cartOpen: boolean
	cartOpenFunction: (open: boolean) => void
} | null>(null)

const ShoppingProvider = (props: { children: ReactNode }) => {
	const [cartOpen, setCartOpen] = React.useState(false)
	const cartOpenFunction = (open: boolean) => {
		setCartOpen(open)
	}
	return (
		<ShoppingContext.Provider value={{ cartOpen, cartOpenFunction }}>
			{props.children}
		</ShoppingContext.Provider>
	)
}

export { ShoppingContext, ShoppingProvider }
